(function () {
    'use strict';
    angular.module('myApp.dataCollector', [])

        .service('DataCollector', [
            '$localStorage', function ($localStorage) {

                var self = this;
                var collectedData = {};
                var currentStep = 'view1';

                self.resetData = function () {
                    collectedData = {};
                };

                self.addDataFromRemote = function (data) {
                    
                        var vivienda={
                            valor: data.Vivienda.valor,
                            vivienda_nueva: data.Vivienda.vivienda_nueva,
                            ccaa: data.Vivienda.ccaa,
                            vivienda_escogida: data.Vivienda.vivienda_escogida,
                            avalista: data.Vivienda.avalista
                            };
                        var titulares={
                            numero_titulares: data.Titulares.numero_titulares,
                            edad_menor: data.Titulares.edad_menor,
                            total_neto_nominas: data.Titulares.total_neto_nominas,
                            total_gastos_mensuales: data.Titulares.total_gastos_mensuales,
                            total_dinero_ahorrado: data.Titulares.total_dinero_ahorrado,
                            valor_otros_inmuebles: data.Titulares.valor_otros_inmuebles

                        };
                    //TODO set current step based on the user status
                    if (data.activo) {
                        var step = 'private1';
                        var i;
                        for(i = 1;i <= 7; i++) {
                            if(data['step' + i] == 'pending') {
                                step = 'private' + i;
                            }
                        }
                        self.setCurrentStep(step);
                    } else {
                        self.addData(data.Titulares.primer_titular);
                        self.addData(titulares);
                        self.addData(vivienda);
                        self.addData(data.Avales);
                        self.addData({Converted: data.Converted});
                        //User is not authenticated
                        if (collectedData.Converted) {
                            console.log('User Converted');
                        }

                        if (collectedData.email) {
                            self.setCurrentStep('view2');
                            if (collectedData.numero_titulares) {
                                self.setCurrentStep('view3');
                                if (collectedData.edad_menor && collectedData.total_neto_nominas) {
                                    self.setCurrentStep('success');
                                }
                            }
                        }
                    }
                };

                self.parseTitularesData = function (titulares) {
                    return {
                        num_titulares: titulares.length,
                        titulares: titulares.map(self.parseTitularData)
                    };
                };
                self.parseTitularData = function (titular) {

                    var fecha_nacimiento = titular.fecha_nacimiento.getFullYear() + "-" + ("0" + (titular.fecha_nacimiento.getMonth() + 1)).slice(-2) + "-" + ("0" + titular.fecha_nacimiento.getDate()).slice(-2);
                    var nameParts = titular.nombre.split(' ').filter(function(part) {return !!part;});
                    var name, surname1, surname2;

                    switch (nameParts.length) {
                        case 0:
                        case 1:
                            name = titular.nombre;
                            break;
                        case 2:
                            name = nameParts[0];
                            surname1 = nameParts[1];
                            break;
                        default:
                            surname2 = nameParts.pop();
                            surname1 = nameParts.pop();
                            name = nameParts.join(' ');
                    }

                    return {
                        apellido2: surname2,
                        apellido1: surname1,
                        nombre: name,
                        nif_nie: titular.nif_nie,
                        fecha_nacimiento: fecha_nacimiento || null

                    };
                };
                self.parseTitularesEconomicData = function (titulares) {
                    return {
                        datos_economicos: {
                            titulares: titulares.map(self.parseTitularEconomicData)
                        }
                    };
                };

                self.parseTitularEconomicData = function (titular) {
                    var fecha = titular.fecha_incorporacion || null;
                    if (titular.fecha_incorporacion) {
                        fecha = fecha.getFullYear() + "-" + ("0" + (fecha.getMonth() + 1)).slice(-2) + "-" + ("0" + fecha.getDate()).slice(-2);
                    }
                    return {
                        estado_civil: titular.estado_civil,
                        tipo_contrato: titular.tipo_contrato,
                        fecha_incorporacion: fecha,
                        neto_mensual: titular.neto_mensual,
                        numero_pagas: titular.numero_pagas || null,
                        otros_ingresos: titular.otros_ingresos,
                        gastos_mensuales: titular.gastos_mensuales,
                        entidad_bancaria: titular.entidad_bancaria
                    };
                };
                self.parseUpdateOpeData = function (params) {
                    return {
                        operacion: {
                            tipo_vivienda: params.operacion.tipo_vivienda,
                            valor: params.operacion.valor,
                            dinero_ahorrado: params.operacion.dinero_ahorrado,
                            cp: params.operacion.cp || null,
                            direccion: params.operacion.direccion,
                            bancos_seleccionados: params.operacion.bancos_seleccionados,
                            vivienda_nueva: params.operacion.vivienda_nueva || 'si',
                            contrato_arras: params.operacion.contrato_arras || 'no',
                        }

                    };
                };

                self.parseOfertaData = function (params) {
                    return {
                        oferta_type: params.oferta_type,
                        oferta_years: params.oferta_years,
                        dinero_ahorrado: params.dinero_aportado,
                        valor: params.valor_compra
                    };
                };
                self.addData = function (data) {
                    angular.extend(collectedData, data);
                };

                self.getData = function () {
                    return collectedData;
                };

                self.getDataField = function (field) {
                    return collectedData[field];
                };

                self.getUserData = function () {
                    var data = collectedData;
                    data.token_id = self.getToken();
                    data.buscando = collectedData.buscando|| 'comprar';
                    data.tipo_vivienda='habitual';
                    data.tres_entidades='unknown';
                    return data;
                };

                self.getHouseData = function () {
                    var data = {
                        tipo_vivienda: collectedData.tipo_vivienda || 'habitual',
                        valor: collectedData.valor || 10000,
                        total_dinero_ahorrado: collectedData.total_dinero_ahorrado || 0,
                        vivienda_nueva: collectedData.vivienda_nueva||'si',
                        token_id: self.getToken()
                    };
                    if (collectedData.ccaa) {
                        data.ccaa = collectedData.ccaa;
                    }
                    return data;
                };

                self.getEconomicData = function () {
                    return {
                        numero_titulares: collectedData.numero_titulares || 1,
                        edad_menor: collectedData.edad_menor || 18,
                        total_neto_nominas: collectedData.total_neto_nominas || 100,
                        total_gastos_mensuales: collectedData.total_gastos_mensuales || 100,
                        tres_entidades:collectedData.tres_entidades,
                        token_id: self.getToken()
                    };
                };

                self.getRegistrationData = function () {
                    return {
                        name: collectedData.name || collectedData.nombre,
                        email: collectedData.user || collectedData.email,
                        mobile: collectedData.mobile || collectedData.telefono,
                        password: collectedData.password,
                        type: 'userpassword'
                    };
                };

                self.getLoginData = function () {
                    return {
                        user: collectedData.user || collectedData.email,
                        password: collectedData.password || collectedData.telefono,
                        type: 'userpassword'
                    };
                };

                self.setCurrentStep = function (step) {
                    currentStep = step;
                };

                self.getCurrentStep = function () {
                    return currentStep;
                };

                self.isAllowedStep = function (step) {
                    var currentStep = self.getCurrentStep();
                    return currentStep == 'success' || currentStep == 'confirm' || currentStep >= step;
                };

                self.setToken = function (value) {
                    $localStorage.ds_noauth_token = value;
                };

                self.getToken = function () {
                    return $localStorage.ds_noauth_token || null;
                };

                self.deleteDSToken = function() {

                    $localStorage.dstoken = null;
                };

                return self;
            }
        ]);
}());

