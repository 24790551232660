(function () {
    'use strict';
    angular.module('myApp.API', ['restangular', 'myApp.auth'])

        .service('API', [
            'Restangular', '$q', 'Auth',
            function (Restangular, $q, Auth) {

                var self = this;
                var cursor = null;

                function getHeaders(headers) {
                    headers = headers || {};

                    headers['X-WEB-KEY'] = projectKeys.webKey;

                    var dstoken = Auth.getToken();
                    if (dstoken) {
                        headers["X-DS-TOKEN"] = dstoken;
                    }
                    return headers;
                }

                function parseError(remoteResponse) {
                    switch (remoteResponse.code) {
                        case 'auth-already-exist':
                            return 'El usuario ya existe';
                        case 'user-already-exist':
                            return 'El usuario ya está en uso';
                        case 'security-error':
                            return 'La contraseña no es correcta';
                        case 'not-found':
                            return 'El usuario no existe';
                        case 'recovery_token-already-used':
                            return 'Este enlace ya ha sido utilizado. Puedes solicitar un nuevo correo electrónico para recuperar tu contraseña.';
                        case 'not-allowed':
                            return 'Ya existe un usuario con este email';
                        default:
                            if (remoteResponse.errors && remoteResponse.errors.length) {
                                return remoteResponse.errors[0];
                            }
                            return '¡Lo sentimos! A ocurrido un error. Por favor, inténtalo más tarde.';
                    }
                }


                self.initPreScoring = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/prescoring')
                            .customPOST({origen:projectKeys.source}, 'init', {}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.PreScoring.token_id);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.sendNewUserData = function (data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2')
                            .customPOST(data, 'users', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {

                                reject(parseError(response.data));
                            });
                    });
                };
                self.sendUpdateUserData = function (id, data) {

                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/users')
                            .customPUT(data, id, {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.sendUpdateMySelfData = function (data) {

                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/users/')
                            .customPUT(data, 'myself', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    self.getDocumentMyself()
                                        .then(function(user) {
                                            resolve(user);
                                        });
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.prepareDocuments = function (type, data) {

                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/prepare_documents/')
                            .customPOST(data, type, {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.sendUserData = function (data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2')
                            .customPUT(data, 'prescoring', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.sendHouseData = function (data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2')
                            .customPUT(data, 'prescoring', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.sendEconomicData = function (data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2')
                            .customPUT(data, 'prescoring', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getPreScoring = function (token) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/prescoring')
                            .get({token_id: token}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.PreScoring);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getCurrentData = function (token) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/prescoring')
                            .get({token_id: token}, getHeaders())
                            .then(function (response) {
                                if(response.code == 201 || response.code == 200 || response.code == 'ok') {
                                    resolve(response.data.PreScoring);
                                } else {
                                    reject(parseError(response.data));
                                }
                            })
                            .catch(function (response) {
                                console.log(response);
                                reject(parseError(response.data));
                            });
                    });
                };
                self.getCurrentDataLogin = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/scoring/')
                            .customGET('data', {}, getHeaders({'Content-Type': "application/x-www-form-urlencoded'"}))
                            .then(function (response) {
                                if(response.code == 201 || response.code == 200 || response.code == 'ok') {
                                    resolve(response.data.Scoring);
                                } else {
                                    reject(parseError(response.data));
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.getDocumentMyself = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/users/')
                            .customGET('myself', {}, getHeaders())
                            .then(function (response) {
                                if(response.code == 201 || response.code == 200 || response.code == 'ok') {
                                    resolve(response.data.HellotecaUser);
                                } else {
                                    reject(parseError(response.data));
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.UploadFile = function (url, files) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .oneUrl({}, url)
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.code == 'ok' || response.success || response.code == 201) {
                                    var uploadUrl = response.data.uploadUrl;
                                    var formData = new FormData();
                                    formData.append('files', files);
                                    Restangular
                                        .oneUrl({}, uploadUrl)
                                        .customPOST(formData, '', undefined, getHeaders({
                                            'content-Type': undefined
                                        }))
                                        .then(function (response) {
                                            if (response.status == 201 || response.success || response.code == 201) {
                                                self.getDocumentMyself().then(function(myselfData) {
                                                    resolve(myselfData);
                                                });
                                            } else {
                                                if (response.errors && response.errors.length) {
                                                    reject(parseError(response.data));
                                                } else {
                                                    reject('Error al subir archivo');
                                                }
                                            }

                                        });

                                } else {
                                    if (response.errors && response.errors.length) {
                                        reject(parseError(response.data));
                                    } else {
                                        reject('Error al subir archivo');
                                    }
                                }
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.deleteDocument = function (id) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/documents/myself/id/')
                            .customDELETE(id, {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    self.getDocumentMyself().then(function(myselfData) {
                                        resolve(myselfData);
                                    });
                                }
                            })
                            .catch(function (response) {

                                reject(parseError(response.data));
                            });
                    });
                };
                self.getResultBancos = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/scoring/')
                            .customGET('data', {}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.HellotecaUser);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getCCAAs = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/variables/ccaa')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.ccaa);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.getEstadoCivil = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('/helloteca/v2/variables/estado_civil')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.estado_civil);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.getTipoVivienda = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('/helloteca/v2/variables/tipo_vivienda')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.tipo_vivienda);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.getTipoContrato = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('/helloteca/v2/variables/tipo_contrato')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.tipo_contrato);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.getBanco = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('/helloteca/v2/variables/banco')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.banco);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };
                self.getHellotecaBanco = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('/helloteca/v2/variables/banco')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.banco.filter(function(item){
                                       
                                        return item.status !== 0;
                                    }));
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getProvinces = function () {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/variables/provincia')
                            .get({}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.provincia);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getUsers = function (params) {

                    params = angular.merge({
                        limit: 50,
                        cursor: cursor,
                        order: 'DateUpdating',
                        order_type: 'ASC'
                    }, params || {});

                    return $q(function (resolve, reject) {
                        Restangular
                            .one('/helloteca/v2')
                            .customGET('users', params, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getUser = function (id) {

                    return $q(function (resolve, reject) {
                        Restangular
                            .one('/helloteca/v2/users')
                            .customGET(id, {}, getHeaders())
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response.data.HellotecaUser);
                                }

                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.postUrl = function (url, data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one(url.replace(Restangular.configuration.baseUrl, ''))
                            .customPOST(data, '', {}, getHeaders())
                            .then(function (response) {
                                resolve(response);
                                /*
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                                */
                            })
                            .catch(function (response) {
                                reject(parseError(response.data));
                            });
                    });
                };

                self.getBroker = function(brokerId) {
                    var deferred = $q.defer();
                    var brokers = {
                        hipotecalia: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/hipotecalia-positivo.png',
                            logoUrlFooter: 'images/brokers/hipotecalia-negativo.png',
                            color:'#71ce52',
                            terminosUrl:'https://helloteca.com/terminos-y-condiciones',
                            privacidadUrl:'https://hipotecalia.es/politica-de-privacidad/',
                            title:"HIPOTECAS 100% FINANCIANCIÓN",
                            subtitle:"Donde tu banco no llega, nosotros Sí",
                            description:[
                                {text: '¿Tu Banco no da una Hipoteca?'},
                                {text: '¿No tienes ahorrado lo suficiente?'},
                                {text: '¿Necesitas liquidez para una reforma o negocio?'},
                                {text: '¿Quieres mudarte pero aun no has vendido tu vivienda?'}
                            ]
                        },
                        creditoh: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/creditoh-positivo.png',
                            logoUrlFooter: 'images/brokers/creditoh-negativo.png',
                            color:'#ff2343',
                            terminosUrl:'https://creditoh.com/assets/uploads/2018/10/CONDICIONES-GENERALES-WEB-CREDITOH-2018.pdf',
                            privacidadUrl:'https://creditoh.com/politica-de-privacidad',
                            title:"HIPOTECAS 100% FINANCIANCIÓN",
                            subtitle:"Donde tu banco no llega, nosotros Sí",
                            description:[
                                {text: '¿Tu Banco no da una Hipoteca?'},
                                {text: '¿No tienes ahorrado lo suficiente?'},
                                {text: '¿Necesitas liquidez para una reforma o negocio?'},
                                {text: '¿Quieres mudarte pero aun no has vendido tu vivienda?'}
                            ]
                        },
                        hipoteca100: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/hipoteca100-positivo.png',
                            logoUrlFooter: 'images/brokers/hipoteca100-negativo.png',
                            color:'#e4a12b',
                            terminosUrl:'https://helloteca.com/terminos-y-condiciones',
                            privacidadUrl:'https://www.tuhipoteca100.es/privacidad.html',
                            title:"HIPOTECAS 100% FINANCIANCIÓN",
                            subtitle:"Donde tu banco no llega, nosotros Sí",
                            description:[
                                {text: '¿Tu Banco no da una Hipoteca?'},
                                {text: '¿No tienes ahorrado lo suficiente?'},
                                {text: '¿Necesitas liquidez para una reforma o negocio?'},
                                {text: '¿Quieres mudarte pero aun no has vendido tu vivienda?'}
                            ]
                        },
                        servihabitat: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/servihabitat-positivo.png',
                            logoUrlFooter: 'images/brokers/servihabitat-negativo.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.servihabitat.com/es/terminos-generales-de-uso',
                            privacidadUrl:'https://www.servihabitat.com/es/politica-de-cookies',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        "alfa-levante": {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/alfalevante.png',
                            logoUrlFooter: 'images/brokers/alfalevante.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.alfamislata.com/aviso-legal/',
                            privacidadUrl:'https://www.alfamislata.com/politica-de-privacidad/',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        lebiimmo: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/lebimmo-logo.png',
                            logoUrlFooter: 'images/brokers/lebimmo-logo.png',
                            color:'#e4a12b',
                            terminosUrl:'https://helloteca.com/terminos-y-condiciones.html',
                            privacidadUrl:'https://helloteca.com/politica-privacidad',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        apisa: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/logo_apisa.png',
                            logoUrlFooter: 'images/brokers/logo_apisa.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.grupoapisa.com/',
                            privacidadUrl:'https://www.grupoapisa.com/',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        valles: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/valles_trans.png',
                            logoUrlFooter: 'images/brokers/valles.png',
                            color:'#e4a12b',
                            terminosUrl:'http://www.vallesinmobiliaria.com/seccion/condiciones',
                            privacidadUrl:'http://www.vallesinmobiliaria.com/seccion/privacidad',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        "lup-sevilla": {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/luxunique.png',
                            logoUrlFooter: 'images/brokers/luxunique.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.luxuniqueproperties.com/terms-of-user/',
                            privacidadUrl:'https://www.luxuniqueproperties.com/privacy-policy/',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        solerdura: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/soler&dura.jpg',
                            logoUrlFooter: 'images/brokers/soler&dura.jpg',
                            color:'#e4a12b',
                            terminosUrl:'http://www.solerdura.com/aviso-legal/',
                            privacidadUrl:'http://www.solerdura.com/aviso-legal/',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        arumi: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/arumi.png',
                            logoUrlFooter: 'images/brokers/arumi.jpg',
                            color:'#e4a12b',
                            terminosUrl:'http://www.arumiassociats.com/pages/legal/lang/es',
                            privacidadUrl:'http://www.arumiassociats.com/pages/lopd/lang/es',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        casasicasas: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/c&c.png',
                            logoUrlFooter: 'images/brokers/c&cfooter.png',
                            color:'#e4a12b',
                            terminosUrl:'http://www.casasicasas.net/page.php?8',
                            privacidadUrl:'http://www.casasicasas.net/page.php?8',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        ibusa: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/logo-ibusa.png',
                            logoUrlFooter: 'images/brokers/logo-ibusa.jpg',
                            color:'#e4a12b',
                            terminosUrl:'https://www.ibusa.com/',
                            privacidadUrl:'https://www.ibusa.com/',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        egarpis: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/logo_egarpis.png',
                            logoUrlFooter: 'images/brokers/logo_egarpis2.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.infoegarpis.com/texto_legal',
                            privacidadUrl:'https://www.infoegarpis.com/texto_legal',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        finquenmonglobal: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/finqueslogo2_trans.png',
                            logoUrlFooter: 'images/brokers/finqueslogo2.jpg',
                            color:'#e4a12b',
                            terminosUrl:'http://www.finquesmon.com/politica_de_privacidad',
                            privacidadUrl:'http://www.finquesmon.com/politica_de_privacidad',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        lucasfox: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/lucasfox-positivo.png',
                            logoUrlFooter: 'images/brokers/lucasfox-negativo.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.lucasfox.es/terminos-y-condiciones.html',
                            privacidadUrl:'https://www.lucasfox.es/politica-de-privacidad.html',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        area46: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/area.png',
                            logoUrlFooter: 'images/brokers/AREA46.jpg',
                            color:'#e4a12b',
                            terminosUrl:'https://www.area46inmobiliaria.es/texto_legal',
                            privacidadUrl:'https://www.area46inmobiliaria.es/texto_legal',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        comprarcasa: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/compracasa-positivo.png',
                            logoUrlFooter: 'images/brokers/comprarcasa-negativo.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.comprarcasa.com/casosuso?lang=es',
                            privacidadUrl:'https://www.comprarcasa.com/casosuso?lang=es',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        grupo90: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-grupo90-broker.jpg',
                            logoUrl: 'images/brokers/logo-grupo90.jpg',
                            logoUrlFooter: 'images/brokers/logo-grupo90-negativo.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.grupo90.com/terms-of-user/',
                            privacidadUrl:'https://www.grupo90.com/politica-de-privacidad/',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        grupohogares: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/grupohogares-positivo-trans.png',
                            logoUrlFooter: 'images/brokers/grupohogares-negativo.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.grupohogares.es/',
                            privacidadUrl:'https://www.grupohogares.es/',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        aedashomes: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/aedashome-positivo.png',
                            logoUrlFooter: 'images/brokers/aedashome-negativo.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.aedashomes.com/legal-renders',
                            privacidadUrl:'https://www.aedashomes.com/legal',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        solvia: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/solvia-positivo.png',
                            logoUrlFooter: 'images/brokers/solvia-negativo.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.solvia.es/es/aviso-legal',
                            privacidadUrl:'https://www.solvia.es/es/politica-cookies',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        ciceronegroup: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/cicerone-positivo.png',
                            logoUrlFooter: 'images/brokers/cicerone.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.ciceronegroup.com/informacion/aviso-legal',
                            privacidadUrl:'https://www.ciceronegroup.com/informacion/politica-de-privacidad',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        kapitalia: {
                            source: brokerId,
                            code: brokerId,
                            headerUrl: 'images/fondo-hipotecalia-broker.jpg',
                            logoUrl: 'images/brokers/kapitalia_trans.png',
                            logoUrlFooter: 'images/brokers/kapitalia_trans.png',
                            color:'#e4a12b',
                            terminosUrl:'https://www.kapitalia.net/pagina/2/aviso-legal',
                            privacidadUrl:'https://www.kapitalia.net/pagina/26/politica-de-cookies',
                            title:' ',
                            subtitle: 'YA TIENES LA MEJOR VIVIENDA, AHORA DÉJANOS OFRECERTE LA MEJOR FINANCIACIÓN',
                            description:[
                                {text: 'Los mejores convenios con los Bancos'},
                                {text: 'Las mejores condiciones de financiación'},
                                {text: 'Te presentamos las mejores ofertas personalizadas dependiendo de tu perfil'},
                                {text: 'Gratis hasta un 90% de Financiación, pero si quieres un 100% no te preocupes, también tenemos soluciones!'},
                                {text: 'Agiliza todo el proceso de compra con nosotros...'},
                            ]
                                
                            
                        },
                        default: {
                            source: brokerId,
                            code: 'default',
                            headerUrl: 'images/helloteca_wizzard_banner.jpg',
                            logoUrl: 'images/logo.svg',
                            logoUrlFooter: false,
                            color:'#22CCD5',
                            terminosUrl:'https://helloteca.com/terminos-y-condiciones',
                            privacidadUrl:'https://helloteca.com/politica-privacidad',
                            title:"HIPOTECAS 100% FINANCIANCIÓN",
                            subtitle:"Donde tu banco no llega, nosotros Sí",
                            description:[
                                {text: '¿Tu Banco no da una Hipoteca?'},
                                {text: '¿No tienes ahorrado lo suficiente?'},
                                {text: '¿Necesitas liquidez para una reforma o negocio?'},
                                {text: '¿Quieres mudarte pero aun no has vendido tu vivienda?'}
                            ]
                        }
                    };

                    if(brokers[brokerId]) {
                        deferred.resolve(brokers[brokerId]);
                    } else {
                        deferred.resolve(brokers['default']);
                    }
                    
                    return deferred.promise;
                };
                self.sendBrokerInit = function (data) {
                    return $q(function (resolve, reject) {
                        Restangular
                            .one('helloteca/v2/prescoring')
                            .customPOST(data, 'broker_init', {}, getHeaders({
                                'Content-Type': "application/x-www-form-urlencoded"
                            }))
                            .then(function (response) {
                                if (response.errors && response.errors.length) {
                                    reject(parseError(response.data));
                                } else {
                                    resolve(response);
                                }
                            })
                            .catch(function (response) {

                                reject(parseError(response.data));
                            });
                    });
                };

                return self;
            }
        ]);
}());
