(function() {
	'use strict';
	angular.module('myApp.auth', ['restangular', 'ngStorage'])

		   .service('Auth', [
			   'Restangular', '$q', '$localStorage', function(Restangular, $q, $localStorage) {

				   var self = this;

				   function parseUser(remoteData) {
					   return {
						   email: remoteData.UserEmail,
						   name: remoteData.UserName,
						   mobile: remoteData.UserMobile
					   };
				   }

				   function parseError(remoteResponse) {
					   switch(remoteResponse.code) {
                           case 'auth-already-exist':
                               return 'El usuario ya existe';
						   case 'user-already-exist':
							   return 'El usuario ya está en uso';
                            case 'security-error':
							   return 'La contraseña no es correcta';
						   case 'not-found':
							   return 'El usuario no existe';
                            case 'recovery_token-already-used':
                                return 'Este enlace ya ha sido utilizado. Puedes solicitar un nuevo correo electrónico para recuperar tu contraseña.';

						   default:
							   if(remoteResponse.errors && remoteResponse.errors.length) {
								   return remoteResponse.errors[0];
							   }
							   return '¡Lo sentimos! A ocurrido un error. Por favor, inténtalo más tarde.';
					   }
				   }

				   function saveToken(remoteToken) {
					   $localStorage.dstoken = remoteToken;
				   }
                   function deleteAuthToken() {
                    
                       $localStorage.ds_noauth_token = '';
                   }

				   function getHeaders(headers) {
	                    headers = headers || {};

	                    headers['X-WEB-KEY'] = projectKeys.webKey;

	                    return headers;
	                }

				   self.getToken = function() {
					   return $localStorage.dstoken;
				   };

				   self.register = function(data) {
					   return $q(function(resolve, reject) {
						   Restangular
							   .one('helloteca/v2')
							   .customPOST(data, 'up', {}, {
                                   'Content-Type': "application/x-www-form-urlencoded",
                                   'X-WEB-KEY': projectKeys.webKey
                               })
							   .then(function(response) {
								   if((response.code == 'ok'  || response.success || response.code == 201)) {
									   self.login({
										   password: data.password,
										   user: data.email,
										   type: data.type
									   }).then(function(user) {
										   resolve(user);
									   });

								   } else {
									   reject(parseError(response.data));
								   }
							   })
							   .catch(function(response) {
								   reject(parseError(response.data));
							   });
					   });
				   };

				   self.login = function(data) {
					   return $q(function(resolve, reject) {
						   Restangular
							   .one('helloteca/v2')
							   .customPOST(data, 'in', {}, getHeaders({
								   'Content-Type': "application/x-www-form-urlencoded"
							   }))
							   .then(function(response) {
								   if(response.code == 'ok'  || response.success || response.code == 201) {
								   	 
									   saveToken(response.data.dstoken);
									   resolve(response);

								   } else {
									   reject(parseError(response.data));
								   }
							   })
							   .catch(function(response) {
								   reject(parseError(response.data));
							   });
					   });
				   };

                   self.recovery = function(email) {
                       return $q(function(resolve, reject) {
                           var data = { email: email };
                           Restangular
                               .one('helloteca/v2')
                               .customPOST(data, 'recovery', {}, getHeaders({}))
                               .then(function(response) {
                                   if (response.code == 'ok'  || response.success || response.code == 201) {
                                       resolve();

                                   } else {
                                       reject(parseError(response.data));
                                   }
                               })
                               .catch(function(response) {
                                   reject(parseError(response.data));
                               });
                       });
                   };

                   self.reset = function(pass, token) {
                       return $q(function(resolve, reject) {
                           var data = {
                               recovery_token: token,
                               password: pass
                           };
                           Restangular
                               .one('helloteca/v2')
                               .customPUT(data, 'recovery', {}, getHeaders({ 'Content-Type': "application/x-www-form-urlencoded" }))
                               .then(function(response) {
                                   if (response.code == 'ok'  || response.success || response.code == 201) {
                                        resolve();

                                   } else {
                                       reject(parseError(response.data));
                                   }
                               })
                               .catch(function(response) {
                                   reject(parseError(response.data));
                               });
                       });
                   };
                   self.change_pass = function(email, old_pass, new_pass) {
                        var data = {
                               user: email,
                               password: old_pass,
                               type: "userpassword",
                               new_password: new_pass,
                           };
                       return $q(function(resolve, reject) {
                           Restangular
                               .one('helloteca/v2')
                               .customPOST(data, 'in', {}, getHeaders({
                                   'Content-Type': "application/x-www-form-urlencoded"
                               }))
                               .then(function(response) {
                                   if(response.code == 'ok'  || response.success || response.code == 201) {
                                     
                                       saveToken(response.data.dstoken);
                                       resolve(response);

                                   } else {
                                       reject(parseError(response.data));
                                   }
                               })
                               .catch(function(response) {
                                   reject(parseError(response.data));
                               });
                       });
                   };

				   self.check = function() {
					   return $q(function(resolve, reject) {
						   var token = self.getToken();
						   if(token) {
							   Restangular
								   .one('helloteca/v2')
								   .customGET('check', {}, getHeaders({
									   'X-DS-TOKEN': token
								   }))
								   .then(function(response) {
									   if(response.code == 'ok' || response.success || response.code == 201) {
										   var user = response.data.User;

										   if(user) {
											   // BEGIN Intercom integration
											   /*
											   var w = window;
											   var ics = w.intercomSettings;
											   ics.name = user.UserName;
											   ics.email = user.UserEmail;
                                               activate_intercom();
                                               */
											   // END Intercom integration.

											   resolve(parseUser(user));

										   } else {
											   resolve(false);
										   }

									   } else {
										   if(response.code < 500) {
											   saveToken(false);
										   }
										   resolve(false);
									   }
								   })
								   .catch(function(response) {
                                       saveToken(false);
									   reject(response);
								   });
						   } else {
							   resolve(false);
						   }
					   });
				   };

				   self.logout = function() {
					   return $q(function(resolve) {
						   var token = self.getToken();
                           saveToken();
                           deleteAuthToken();

                           if(token) {
							   Restangular
								   .one('helloteca/v2')
								   .customGET('logout', {}, getHeaders({
									   'X-DS-TOKEN': token
								   }))
								   .then(function() {
									   resolve(true);
								   })
								   .catch(function() {
									   resolve(false);
								   });
						   } else {
							   resolve(true);
						   }
					   });
				   };

				   self.getUser = function() {
					   return self.check();
				   };

				   return self;
			   }
		   ]);
}());