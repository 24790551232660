(function() {
	'use strict';
	angular.module('myApp.public', ['ui.router', 'myApp.dataCollector', 'myApp.auth', 'myApp.API'])

		   .config([
			   '$stateProvider', function($stateProvider) {
				   $stateProvider.state('home.public', {
					   url: '',
					   abstract: true,
					   views: {
					    header: {
					       templateUrl: 'public/header.html',
						   controller: 'PublicCtrl',
						   controllerAs: 'publicCtrl', 
					    },

					    content: {
					    	template: '<div ui-view="content"></div>',
						  	controller: 'PublicCtrl',
						  	controllerAs: 'publicCtrl', 
					    }
					   },
					   resolve: {
						   redirectToPrivate: [
							   '$state', 'user', '$stateParams',  function($state, user, $stateParams) {
						   			if(user) {
						   				$state.go('home.private.private1',{'broker':$stateParams.broker}, {reload: true});
									}
							   }
						   ]
					   }
				   });
			   }
		   ])

		   .controller('PublicCtrl', [
			   '$scope', 'DataCollector', 'user', '$state','broker', function($scope, DataCollector, user, $state, broker) {
				   var ctrl = this;
				  

				   ctrl.activeStep = $state.current.name.split('.').pop();

				   if(user) {
					   DataCollector.addData(user);
				   }
				   
			   }
		   ]);
}());
