/*
 * https://cloudframework.io
 * Brokers app for Helloteca
 */
(function () {
    'use strict';
    // Declare app level module which depends on views, and components
    angular.module('myApp', [
        'ui.router',
        'myApp.home',
        'myApp.public',
        'myApp.login',
        'myApp.register',
        'myApp.recoveryPassword',
        'myApp.resetPassword',
        'myApp.logout',
        'myApp.private',
        'myApp.private1',
        'myApp.inicio',
        'myApp.view1',
        'myApp.success',
        'spinner',
        'ngMessages',
        'templates',
        'ngFileUpload'
    ])
        .config([
            '$locationProvider',
            '$urlRouterProvider',
            'RestangularProvider',
            function ($locationProvider, $urlRouterProvider, RestangularProvider) {
                $locationProvider.hashPrefix('!');
                $urlRouterProvider.otherwise('/default');

                RestangularProvider.setBaseUrl('https://api.cloudframework.io');
            }
        ])
        .config(['$compileProvider', function ($compileProvider) {
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|javascript):/);
        }])
        .config( ['$anchorScrollProvider', function($anchorScrollProvider) {
            $anchorScrollProvider.disableAutoScrolling();
        }])
        .run(['$http', function ($http) {
            $http.defaults.withCredentials = true;
        }])
        .run([
            '$rootScope', function ($rootScope) {
                $rootScope.$on('$stateChangeError', function (e, toState, toParams, fromState, fromParams, error) {
                    console.log(error);
                });
            }
        ])
        .run(['$uibModal', '$window', function($modal, $window) {
            $window.alert = function(message) {
                $modal.open({
                    templateUrl: 'modals/alert.html',
                    controller: ['$uibModalInstance', 'message', function($uibModalInstance, message) {
                        this.close = $uibModalInstance.close;
                        this.message = message;
                    }],
                    controllerAs: 'alertCtrl',
                    resolve: {
                        message: function() {
                            return message;
                        }
                    }
                });
            };
            $window.confirm = function(message, buttons, title) {
                $modal.open({
                    templateUrl: 'modals/confirm.html',
                    controller: ['$uibModalInstance', 'message', 'buttons', 'title',
                        function($uibModalInstance, message, buttons, title) {
                            this.close = $uibModalInstance.close;
                            this.message = message;
                            this.buttons = buttons;
                            this.title = title;

                            this.clickButton = function(button) {
                                $uibModalInstance.close();
                                button.action();
                            };


                        }
                    ],
                    controllerAs: 'confirmCtrl',
                    resolve: {
                        message: function() {
                            return message;
                        },
                        buttons: function() {
                            return buttons;
                        },
                        title: function() {
                            return title;
                        }
                    }
                });
            };
        }])
        .filter('capitalize', function () {
            return function (input) {
                return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
            };
        })
        .directive('confirmPass', function () {
            return {
                require: "ngModel",
                scope: {
                    otherModelValue: "=confirmPass"
                },
                link: function (scope, element, attributes, ngModel) {

                    //For DOM -> model validation
                    ngModel.$parsers.unshift(function (value) {
                        var valid = value == scope.otherModelValue;
                        ngModel.$setValidity('match', valid);
                        return valid ? value : undefined;
                    });

                    //For model -> DOM validation
                    ngModel.$formatters.unshift(function (value) {
                        ngModel.$setValidity('match', value == scope.otherModelValue);
                        return value;
                    });
                }
            };
        })
        .directive('modalDialog', ['$window', '$templateCache', '$compile', '$http', '$timeout',
            function($window, $templateCache, $compile, $http, $timeout) {
                return {
                    restrict: 'EA',
                    scope: {
                        show: '=',
                        modalUser: '=',
                        onSubmit: '&',
                        template: '@',
                        modal: '@'
                    },
                    controllerAs: 'modalCtrl',
                    controller: ['$scope', function($scope) {
                        var ctrl = this;

                        ctrl.user = $scope.modalUser;
                        ctrl.onSubmitParams = false;

                        ctrl.hideModal = function() {
                            $($scope.modal).modal('hide');
                        };

                        ctrl.onSubmit = function(form) {
                            ctrl.error = '';
                            if (form.$invalid) {
                                ctrl.formSubmitted = true;

                            } else {
                                $scope.onSubmit({user: ctrl.onSubmitParams || ctrl.user})
                                    .then(function(response) {
                                        ctrl.hideModal();
                                    })
                                    .catch(function(error) {
                                        if(error) {
                                            ctrl.error = error;
                                        } else {
                                            ctrl.hideModal();
                                        }
                                    });
                            }
                        };

                        ctrl.cancel = function() {
                            ctrl.hideModal();
                        };

                        $scope.$watch('show', function(val) {
                            $timeout(function() {
                                if(val) {
                                    $($scope.modal).modal('show');
                                } else {
                                    $($scope.modal).modal('hide');
                                }
                            });
                        });

                        $('body').on('hide.bs.modal', function (e) {
                            $timeout(function() {
                                $scope.show = false;
                            });
                        });
                    }],
                    link: function(scope, element, attrs) {
                        $http.get(scope.template, {cache: $templateCache}).then(function(tplContent){
                            element.replaceWith($compile(tplContent.data)(scope));
                        });
                    }
                };
            }
        ])
        .directive('accessibleForm', function () {
            return {
                restrict: 'A',
                link: function (scope, elem) {

                    // set up event handler on the form element
                    elem.on('submit', function () {

                        // find the first invalid element
                        var firstInvalid = elem[0].querySelector('input.ng-invalid');
                        if(!firstInvalid) {
                            firstInvalid = elem[0].querySelector('select.ng-invalid');
                        }

                        // if we find one, set focus
                        if (firstInvalid) {
                            firstInvalid.focus();
                        }
                    });
                }
            };
        });

    try {
        angular.module("templates");
    } catch (o) {
        angular.module("templates", []);
    }
}());

/* -- Intercom -- */
/*
window.intercomSettings = {
    app_id: projectKeys.intercomId
};

function l() {

}

function activate_intercom() {

    'use strict';
    var w = window;
    var ic = w.Intercom;

    if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update', intercomSettings);
    } else {
        var d = document;
        var i = function () {
            i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
            i.q.push(args);
        };
        w.Intercom = i;

        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + projectKeys.intercomId;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    }
}
*/
