(function () {
    'use strict';
    angular.module('myApp.view1', ['ui.router', 'myApp.dataCollector', 'myApp.API'])

        .config([
            '$stateProvider', function ($stateProvider) {
                $stateProvider.state('home.public.view1', {
                    url: '/{token_id}',
                    views: {
                                content: {
                                    templateUrl: 'view1/view1.html',
                                    controller: 'View1Ctrl',
                                    controllerAs: 'ctrl',
                                }
                            },
                    data: {
                        isStep: true
                    },
                    resolve: {
                        regions: ['API', function (API) {
                            return API.getCCAAs();
                        }],
                        token: [
                            'DataCollector', '$state', 'API', '$stateParams', function (DataCollector, $state, API, $stateParams) {
                                DataCollector.setToken($stateParams.token_id);
                                var token = DataCollector.getToken();
                                return API.getCurrentData(token)
                                    .then(function (data) {
                                        DataCollector.addDataFromRemote(data);


                                        return token;
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                        DataCollector.setToken();
                                        $state.go('home.public.login', $stateParams, {reload: true});
                                    });
                            }
                        ]
                    },
                });
            }
        ])

        .controller('View1Ctrl', [
            'DataCollector', '$state', 'API', '$scope', 'regions', '$stateParams',
            function (DataCollector, $state, API, $scope, regions, $stateParams) {
                var ctrl = this;
                ctrl.regions = regions;


                //BEGIN LuckyOrange
                window._loq = window._loq || []; // ensure queue available
                // https://help.luckyorange.com/article/126-tagging-with-javascript
                window._loq.push(["tag", "view1", false, true]); // this will tag, won't star, and will append the tag
                //END LuckyOrange

                ctrl.formSubmitted = false;


                ctrl.stepData = DataCollector.getUserData();

                if(ctrl.stepData.vivienda_nueva == 'unknown') {
                    ctrl.stepData.vivienda_nueva = null;
                }

                //BEGIN MIXPANEL
                mixpanel.track("BrokerPrescoringFormLoaded");

                // MIXPANEL userData
                if (projectKeys.mixpanelUserId != ctrl.stepData.email) {
                    mixpanel.alias(ctrl.stepData.email);
                    projectKeys.mixpanelUserId = ctrl.stepData.email;
                }
                mixpanel.identify(ctrl.stepData.email);
                mixpanel.people.set({
                    "$email": ctrl.stepData.email,
                    "email verified": false,
                    "$phone": ctrl.stepData.telefono,
                    "$first_name": ctrl.stepData.nombre_completo,
                    "phone verified": false,
                    "PreScoring Updated": new Date(),
                    "isThirdParty": false,
                    "ThirdPartyName": ""
                });

                mixpanel.people.set_once({
                    "$created": new Date(),
                    "Broker PreScoring First Date": new Date()
                });
                // END MIXPANEL userData

                // LuckyOrange User Data
                var customData = {
                    'name': ctrl.stepData.nombre_completo,
                    'email': ctrl.stepData.email
                };

                window._loq = window._loq || [];
                window._loq.push(['custom', customData]);
                // END LuckyOrange


                ctrl.onSubmit = function (form) {
                    if (form.$invalid) {
                        ctrl.formSubmitted = true;
                    } else {
                        $scope.$emit('loading:show');

                        DataCollector.addData(ctrl.stepData);
                        mixpanel.track("BrokerPrescoringFormClicked");


                        API.sendUserData(DataCollector.getUserData())
                            .then(function (ret) {
                                DataCollector.setCurrentStep('success');
                                var userData = DataCollector.getUserData();

                                mixpanel.track("BrokerPrescoringFormOK");
                                // TAG MANAGER
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                      event: "FormularioBrokers", 
                                      tipoFormulario: 'formularioBrokerPrescoring', 
                                });
                                //END TAG MANAGER
                                // BEGIN Intercom integration
                                /*
                                try {

                                  // deactivate intercom
                                  var w = window;
                                  var ics = w.intercomSettings;
                                  ics.name = userData.nombre_completo;
                                  ics.email = ret.data.PreScoring.Intercom.intercom_email;
                                  ics.user_id = ret.data.PreScoring.Intercom.intercom_id;
                                  ics.type = 'lead';
                                  activate_intercom();

                                }
                                catch (err) {
                                    console.log('Error retrieving ret.data.PreScoring.Intercom.intercom_id');
                                    console.log(err);
                                }
                                */
                                // END Intercom integration.

                                $state.go('home.public.success', $stateParams);
                            })
                            .catch(function (error) {
                                mixpanel.track("BrokerPrescoringFormError");
                                ctrl.error = error;
                            });
                    }
                };

            }
        ])

        .directive('confirmEmail', function () {
            return {
                require: "ngModel",
                scope: {
                    otherModelValue: "=confirmEmail"
                },
                link: function (scope, element, attributes, ngModel) {

                    //For DOM -> model validation
                    ngModel.$parsers.unshift(function (value) {
                        var valid = value == scope.otherModelValue;
                        ngModel.$setValidity('match', valid);
                        return valid ? value : undefined;
                    });

                    //For model -> DOM validation
                    ngModel.$formatters.unshift(function (value) {
                        ngModel.$setValidity('match', value == scope.otherModelValue);
                        return value;
                    });
                }
            };
        });
}());