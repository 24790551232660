(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('public/header.html',
    '<div ui-view="header"><header><div class="top_menu_bar_background" ng-style="{\'background-image\':\'url({{homeCtrl.broker.headerUrl}})\'}"><div id="top-menu-bar"><img ng-src="{{homeCtrl.broker.logoUrl}}" alt="Helloteca" width="320px" class="d-none d-md-block"> <img ng-src="{{homeCtrl.broker.logoUrl}}" alt="Helloteca" width="120px" class="d-block d-md-none"><div class="link d-blok d-xl-inline" ng-if="!homeCtrl.user && homeCtrl.activeStep!=\'login\'"><a ui-sref="home.public.login" ui-sref-opts="{ reload: true }" class="theme small">INICIAR SESIÓN</a></div><div class="link d-inline" ng-if="homeCtrl.user&&homeCtrl.activeStep!=\'login\'"><span class="d-none d-xl-inline">{{ homeCtrl.user.name }} -</span> <a ui-sref="home.logout" ui-sref-opts="{ reload: true }" class="theme">Salir</a></div><div class="link d-inline" ng-if="homeCtrl.activeStep==\'login\'"><a ui-sref="home.public.inicio" ui-sref-opts="{ reload: true }" class="theme">Registro</a></div></div></div></header></div>');
}]);
})();
