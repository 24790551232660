(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('home/home.html',
    '<div ui-view="header" class="prescoring-step-view"></div><div ui-view="content" class="prescoring-step-view"></div><footer><div class="row"><div class="col text-center align-self-center"><img src="images/helloteca-blog-log-black.png" class="image wp-image-1620 attachment-full size-full logo_footer"><div class=""><a class="text-white text-small d-sm-block d-md-inline-block" href="https://helloteca.com/politica-privacidad" target="_blank">Política de privacidad </a><span class="d-sm-none d-md-inline-block">|</span> <a class="text-white text-small" href="https://helloteca.com/terminos-y-condiciones" target=" _blank">Términos y condiciones</a></div></div><div class="col text-center align-self-center" ng-if="homeCtrl.broker.logoUrlFooter"><img ng-src="{{homeCtrl.broker.logoUrlFooter}}" class="image wp-image-1620 attachment-full size-full logo_footer"><div class=""><a class="text-white text-small d-sm-block d-md-inline-block" ng-href="{{homeCtrl.broker.privacidadUrl}}" target="_blank" style="color: {{homeCtrl.broker.color}}!important">Política de privacidad </a><span class="d-sm-none d-md-inline-block">|</span> <a class="text-white text-small" ng-href="{{homeCtrl.broker.terminosUrl}}" target=" _blank" style="color: {{homeCtrl.broker.color}}!important">Términos y condiciones</a></div></div></div></footer>');
}]);
})();
