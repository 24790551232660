(function () {
    'use strict';
    angular.module('myApp.private', ['ui.router','ui.bootstrap', 'myApp.dataCollector', 'myApp.auth', 'myApp.API'])

        .config([
            '$stateProvider',
            function ($stateProvider) {
                $stateProvider.state('home.private', {
                    url: '/private',
                    abstract: true,

                       views: {
                        header: {
                           templateUrl: 'public/header.html',
                           controller: 'PrivateCtrl',
                           controllerAs: 'privateCtrl', 
                        },

                        content: {
                            template: '<div ui-view></div>',
                            controller: 'PrivateCtrl',
                            controllerAs: 'privateCtrl', 
                        }
                       },
                    resolve: {
                        user: [
                            'API', '$state', 'DataCollector', function (API, $state, DataCollector) {
                                return API.getDocumentMyself()
                                    .then(function (user) {

                                        DataCollector.addDataFromRemote(user.PRESCORING.JSON.PreScoring);
                                        DataCollector.setToken();
                                        return user;
                                    })
                                    .catch(function (error) {
                                       $state.go('logout');
                                       console.log(error);
                                    });
                            }
                        ]

                    }
                });
            }
        ])

        .controller('PrivateCtrl', [
            '$scope', 'DataCollector', 'user', 'API', '$state','$rootScope', 'Auth', '$q', '$anchorScroll', '$location','broker',
            function ($scope, DataCollector, user, API, $state,$rootScope, Auth, $q, $anchorScroll, $location, broker) {
                
                var ctrl = this;

                ctrl.broker=broker;
                
                ctrl.user = user; 
                ctrl.state = $state;
                $scope.need_to_access_this = $rootScope.title2;
                ctrl.$root = $rootScope;

                // MIXPANEL DATA
                mixpanel.track("BrokerPrivateLoaded");
                //console.log(ctrl.user);

                //BEGIN LuckyOrange
                window._loq = window._loq || []; // ensure queue available
                window._loq.push(["tag", "broker_private_area",true]); // this will tag, star, and will append the tag
                //END LuckyOrange

               

                $scope.$on('$stateChangeStart', function (event, toState) {
                    window.scrollTo(0, 0);

                    //refreshStepStatus();
                    ctrl.activeStep = toState.name.split('.').pop();

                    if (toState.data && toState.data.isStep) {
                        var stateTree = toState.name.split('.');
                        var targetStateName = stateTree[stateTree.length - 1];
                        if (!DataCollector.isAllowedStep(targetStateName)) {
                            event.preventDefault();
                        }
                    }
                });

                $rootScope.$on('user-update', function(event, user) {
                    ctrl.user = user;
                });

                ctrl.requirePassword = ctrl.user.UserPasswordRequired;
                
                ctrl.setPassword = function(user) {
                    var deferred = $q.defer();

                    // MIXPANEL DATA
                    mixpanel.track("BrokerNewUserChangePasswordClicked");
                    // END MIXPANEL DATA

                    Auth.change_pass(user.email, ctrl.user.movil, user.password)
                        .then(function () {
                            // MIXPANEL DATA
                            mixpanel.track("BrokerNewUserChangePasswordSuccess");
                            // END MIXPANEL DATA
                            deferred.resolve();

                        }, function (error) {
                            // MIXPANEL DATA
                            mixpanel.track("BrokerNewUserChangePasswordError");
                            // END MIXPANEL DATA
                            deferred.reject(error);
                        });
                    return deferred.promise;
                };

                ctrl.scrollToAnchor = function(step) {
                    if($state.current.name == 'private.' + step) {
                        $location.hash('anchor-' + step);
                        $anchorScroll();
                    }
                };

            }
        ])

        .directive('alert', [function () {
            return {
                restrict: 'E',
                template: '<div class="scoring_alert" ng-if="alert" role="alert" ng-bind-html="alert"></div>'
            };
        }])
        .directive('resumen', [function () {
            return {
                restrict: 'E',
                scope: {
                    user: '=',
                    tiposVivienda: '='
                },
                templateUrl: 'private/resumen.html',
                controllerAs: 'resumenCtrl',
                controller: ['$scope', function ($scope) {
                    var ctrl = this;

                    ctrl.user = $scope.user;

                    $scope.tiposVivienda.forEach(function(tipo) {
                        if(tipo.code == ctrl.user.operacion.tipo_vivienda) {
                            ctrl.tipo_vivienda = tipo.name;
                        }
                    });

                    ctrl.totalNominas = 0;
                    ctrl.totalOtrosIngresos = 0;
                    ctrl.totalGastos = 0;
                    ctrl.titulares = [];
                    for (var i = 0; i < $scope.user.datos_economicos.titulares.length; i++) {
                        ctrl.titulares.push({
                            name: $scope.user.titulares[i].nombre_completo,
                            contrato: $scope.user.datos_economicos.titulares[i].tipo_contrato,
                            edad: calculateAge($scope.user.titulares[i].fecha_nacimiento),
                            estado: $scope.user.datos_economicos.titulares[i].estado_civil,
                        });
                        ctrl.totalNominas += $scope.user.datos_economicos.titulares[i].neto_mensual;
                        ctrl.totalOtrosIngresos += $scope.user.datos_economicos.titulares[i].otros_ingresos;
                        ctrl.totalGastos += $scope.user.datos_economicos.titulares[i].gastos_mensuales;
                    }
                    ctrl.porcentajeIngresos = Math.round((ctrl.totalNominas * 100) / (ctrl.totalNominas + ctrl.totalOtrosIngresos));
                    ctrl.porcentajeGastos = Math.round((ctrl.totalGastos * 100) / (ctrl.totalGastos + ctrl.totalNominas + ctrl.totalOtrosIngresos));

                    function calculateAge(birthdate) {
                        var currentYear = (new Date()).getFullYear();
                        var actualYear = birthdate.split('-').shift();

                        return currentYear - actualYear;
                    }

                }]
            };
        }])
        .directive('infomessage', ['$location', '$anchorScroll', function ($location, $anchorScroll) {

            return {
                restrict: 'E',
                scope: {
                    customerInfo: '=info',
                    user: '=',
                    position: '@?'
                },
                templateUrl: 'private/infomessage.html',
                controllerAs: 'infomessageCtrl',
                controller: ['$scope', 'API', '$state', 'DataCollector', function ($scope, API, $state, DataCollector) {
                    var ctrl = this;
                    
                    
                    function refreshMessage() {
                        switch ($scope.customerInfo) {
                            case 'private5_offer_received':
                                ctrl.title = 'Ya tienes tu oferta vinculante';
                                ctrl.description = '';
                                ctrl.class = 'infomessage-success';
                                if($scope.position == 'top') {
                                    ctrl.buttontext = null;
                                    ctrl.buttonAction = null;
                                } else {
                                    ctrl.buttontext = null;
                                    ctrl.buttonAction = null;
                                }
                                break;
                            case 'private5_success':
                                ctrl.title = '¡Enhorabuena! Gracias por confiar en '+$scope.user.origen.toUpperCase()+', pronto nos pondremos en contacto contigo';
                                //ctrl.description = '';
                                ctrl.class = 'infomessage-success';
                                ctrl.buttontext = null;
                                ctrl.buttonAction = null;
                                break;
                            case 'private5_warning':
                                // ctrl.title = 'Sube los documentos para poder cerrar la oferta vinculante';
                                ctrl.title = 'Comienza a trabajar con '+$scope.user.origen.toUpperCase()+' y gestionaremos tu operación en todos los Bancos con los que tenemos convenio. ';
                                // ctrl.description = 'Comienza a trabajar con Helloteca y gestionaremos en todos los Bancos con los que tenemos convenio. ';
                                ctrl.class = 'infomessage-warning';
                                ctrl.buttontext = $scope.user.upload_documents ? $scope.user.upload_documents.texto_boton : 'Evaluar mi operación';
                                ctrl.buttonAction = uploadFiles;
                                break;
                            case 'private5_danger':
                                ctrl.title = 'Por favor, envíanos la documentación necesaria para poder procesar tu petición';
                                //ctrl.description = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ';
                                ctrl.class = 'infomessage-danger';
                                if($scope.position == 'top') {
                                    ctrl.buttontext = null;
                                    ctrl.buttonAction = null;
                                } else {
                                    ctrl.buttontext = null;
                                    ctrl.buttonAction = null;
                                }
                                break;
                            case 'private5_danger2':
                                ctrl.title = 'Error en los archivos adjuntos';
                                ctrl.description = 'Aún no puedes enviar tu petición porque faltan documentos, están en proceso de validación o algunos de los archivos subidos no son válidos. Comprueba el estado de los archivos subidos y si hay algún mensaje de error en el formulario. ';
                                ctrl.class = 'infomessage-danger';
                                if($scope.position == 'top') {
                                    ctrl.buttontext = null;
                                    ctrl.buttonAction = null;
                                } else {
                                    ctrl.buttontext = null;
                                    ctrl.buttonAction = null;
                                }
                                break;
                            case 'private4_success_0':
                                ctrl.title = 'Aún no has recibido ninguna oferta de las entidades que seleccionaste';
                                ctrl.description = 'Seguimos trabajando para conseguir 3 ofertas personalizadas para ti lo antes posibles.';
                                ctrl.buttontext = null;
                                ctrl.class = 'infomessage-warning';
                                break;
                            case 'private4_success_1':
                                ctrl.title = 'Felicidades, ya has recibido 1 oferta de las entidades que seleccionaste';
                                ctrl.description = 'Seguimos trabajando para conseguir las otras dos ofertas.';
                                ctrl.buttontext = 'Ver ofertas recibidas';
                                ctrl.buttonAction = scrollToOffers;
                                ctrl.class = 'infomessage-success';
                                break;
                            case 'private4_success_2':
                                ctrl.title = 'Felicidades, ya has recibido 2 ofertas de las entidades que seleccionaste';
                                ctrl.description = 'Seguimos trabajando para conseguir la tercera oferta.';
                                ctrl.buttontext = 'Ver ofertas recibidas';
                                ctrl.buttonAction = scrollToOffers;
                                ctrl.class = 'infomessage-success';
                                break;
                            case 'private4_success_3':
                                ctrl.title = 'Felicidades, ya has recibido las 3 ofertas de las entidades que seleccionaste';
                                ctrl.description = 'Selecciona la oferta que prefieras y conviértela en una oferta vinculante.';
                                ctrl.buttontext = 'Ver ofertas recibidas';
                                ctrl.buttonAction = scrollToOffers;
                                ctrl.class = 'infomessage-success';
                                break;
                            case 'private4_danger':
                                ctrl.title = 'Lo sentimos pero el porcentaje sobre tus ingresos es demasiado elevado';
                                ctrl.description = 'Tienes que aumentar el dinero aportado para reducir el importe a financiar y que no supere el <b>40%</b>.';
                                ctrl.buttontext = null;
                                ctrl.class = 'infomessage-danger';
                                break;
                            case 'private4_waiting_0':
                                ctrl.title = '¡Solicitud enviada!';
                                ctrl.description = 'En breve la entidad colaboradora se pondrá en contacto contigo por teléfono.';
                                ctrl.buttontext = null;
                                ctrl.class = 'infomessage-success';
                                break;
                            case 'private4_waiting_1':
                            case 'private4_waiting_2':
                            case 'private4_waiting_3':
                                ctrl.title = 'Felicidades, ya has recibido ofertas de la entidad colaboradora';
                                ctrl.description = '';
                                ctrl.buttontext = null;
                                ctrl.class = 'infomessage-success';
                                break;
                            case 'private4_warning':
                                ctrl.title = 'El porcentaje de financiación sobre el valor de compra es demasiado elevado';
                                ctrl.description = 'Modifica el <b>Dinero Aportado</b> para financiar menos del 90%. Si no puedes aportar más dinero, te podemos poner en contacto con un asesor especializado que te ayude a conseguir la financiación. TIENES MUCHO POR GANAR Y POCO POR PERDER :)';
                                if($scope.position != 'top') {
                                    ctrl.buttontext = $scope.user.oferta_personalizada.texto_boton || 'Solicitar asesoramiento especializado';
                                    ctrl.buttonAction = requestOffer;
                                } else {
                                    ctrl.buttontext = null;
                                    ctrl.buttonAction = null;
                                }
                                ctrl.class = 'infomessage-warning';
                                break;
                            case 'private6_success':
                                ctrl.title = '¡Solicitud enviada!';
                                ctrl.description = 'En breve la entidad colaboradora se pondrá en contacto contigo por teléfono. ';
                                ctrl.buttontext = null;
                                ctrl.class = 'infomessage-success';
                                break;
                            default:
                                ctrl.title = null;
                                ctrl.description = null;
                                ctrl.class = null;
                                ctrl.buttontext = null;
                        }
                    }

                    function scrollToOffers() {
                        $location.hash('offers');
                        $anchorScroll();
                    }

                    function requestOffer() {
                        // MIXPANEL DATA
                        mixpanel.track("BrokerPrivateRequestOfferClicked");
                        // END MIXPANEL DATA

                        API.postUrl($scope.user.oferta_personalizada.texto_url, $scope.user.oferta_personalizada)
                            .then(function() {
                                API.getDocumentMyself()
                                    .then(function(user) {

                                        // MIXPANEL DATA
                                        mixpanel.track("BrokerPrivateRequestOfferOK");
                                        // END MIXPANEL DATA

                                        DataCollector.addDataFromRemote(user);
                                        $scope.$emit('user-update', user);
                                        //refreshStatus(user);
                                        if(DataCollector.isAllowedStep('private5')) {
                                            $state.go('private.private5');
                                        } else {
                                            alert(user.step_info);
                                        }
                                    });
                            })
                            .catch(function (error) {
                                // MIXPANEL DATA
                                mixpanel.track("BrokerPrivateRequestOfferError");
                                // END MIXPANEL DATA
                                alert(error);
                            });
                    }

                    function uploadFiles() {
                        API.postUrl($scope.user.upload_documents.texto_url)
                            .then(function() {
                                API.getDocumentMyself()
                                    .then(function(user) {
                                        $scope.customerInfo = 'private5_success';
                                        $scope.$emit('user-update', user);

                                    });
                            })
                            .catch(function (error) {
                                alert(error);
                            });
                    }

                    $scope.$watch('customerInfo', function() {
                        refreshMessage();
                    });
                }]
            };


        }])
        .directive('ngConfirm', ['$uibModal', '$parse',
                function($modal, $parse){
                    
                    return {
                            // So the link function is run before ngClick's, which has priority 0
                            priority: -1,

                            link: function(scope, element, attrs) {
                              element.on('click', function(e) {
                                // Don't run ngClick's handler
                                e.stopImmediatePropagation();
                                e.preventDefault();
                                e.stopPropagation();
                          
                                $modal.open({
                                  templateUrl: 'private/remove-confirm.html',
                                  controller: 'ngConfirmController',
                                  resolve: {
                                    message: function() {
                                      return attrs.ngConfirm;
                                    },
                                    title: function() {
                                      return attrs.ngConfirmTitle;
                                    }
                                  }
                                }).result.then(function() {
                                  // Pass original click as '$event', just like ngClick
                                  $parse(attrs.ngClick)(scope, {$event: e});
                                });
                              });
                            }
                          };
            }])
        .controller('ngConfirmController', ['$scope','$uibModalInstance','message','title', function($scope, $modalInstance, message,title) {
                  $scope.message = message;
                  $scope.title=title;
                  
                  $scope.yes = function() {
                    $modalInstance.close();
                  };
                  
                  $scope.no = function() {
                    $modalInstance.dismiss();
                  };
                }])

        .directive('fileUpload', ['$window', function ($window) {
            return {
                restrict: 'E',
                templateUrl: 'private/file-upload.html',
                scope: true,
                bindToController: {
                    document: '=',
                    parser: '&',
                    fullwidth: '@',
                    label:'@'
                },
                controllerAs: 'fileCtrl',
                controller: ['API', '$scope', '$timeout', function (API, $scope, $timeout) {
                    var ctrl = this;

                    ctrl.files = [];

                    ctrl.upload = function () {

                        mixpanel.track("BrokerPrivateUploadDocClicked");

                        $scope.$emit('loading:show', true);
                        API.UploadFile(ctrl.document.url, ctrl.files)
                            .then(function (response) {
                                mixpanel.track("BrokerPrivateUploadDocOK");
                                ctrl.document = ctrl.parser()(response);
                                $scope.$emit('loading:hide');
                                $timeout(function() {
                                    $scope.$emit('file:uploaded', ctrl.document, ctrl.files, response);
                                }, 100);

                            })
                            .catch(function (error) {
                                mixpanel.track("BrokerPrivateUploadDocError");
                                ctrl.error = error;
                            });

                    };

                    ctrl.removeFile = function (document, file, event) {
                        API.deleteDocument(file.id)
                            .then(function (response) {
                                ctrl.document = ctrl.parser()(response);
                                $timeout(function() {
                                    $scope.$emit('file:removed', document, file, response);
                                }, 100);
                            })
                            .catch(function (error) {
                                ctrl.error = error;
                            });

                    };

                    ctrl.getFileImage = function(file) {
                        switch(file.name.split('.').pop()) {
                            case 'pdf':
                                return 'images/file-pdf.png';
                            case 'png':
                                return 'images/file-png.png';
                            case 'jpg':
                            case 'jpeg':
                                return 'images/file-jpg.png';
                            default:
                                return 'images/doc.png';
                        }
                    };

                    ctrl.getFileStatusImage = function(file) {
                        switch(file.status) {
                            case 'accepted':
                                return 'images/file_ok.png';
                            case 'rejected':
                                return 'images/file-error.png';
                            case 'uploaded':
                                return 'images/file-in-progress.png';
                        }
                    };

                    ctrl.getFileStatusName = function(file) {
                        switch(file.status) {
                            case 'accepted':
                                return 'El archivo ha sido validado';
                            case 'rejected':
                                return file.comments || 'El archivo ha sido rechazado';
                            case 'uploaded':
                                return 'El archivo está siendo procesado';
                        }
                    };

                    ctrl.openFile = function(url, event) {
                        event.preventDefault();
                        event.stopPropagation();

                        $window.open(url, '_blank');
                    };

                }]
            };
        }])
        .directive('tooltip', function(){
            return {
                restrict: 'A',
                link: function(scope, element, attrs){
                    element.hover(function(){
                        // on mouseenter
                        $(element).tooltip('show');
                    }, function(){
                        // on mouseleave
                        $(element).tooltip('hide');
                    });
                }
            };
        })
        .filter('range', function () {
            return function (input, total) {
                total = parseInt(total);

                for (var i = 0; i < total; i++) {
                    input.push(i);
                }

                return input;
            };
        })
        .filter('fileSize', function () {
            return function (bytes) {
                var result = bytes;
                var unit = 'B';
                if(result > 1024) {
                    result = Math.round(result/1024);
                    unit = 'KB';
                }
                if(result > 1024) {
                    result = Math.round(result/1024);
                    unit = 'MB';
                }
                return result + ' ' + unit;
            };
        });

}());