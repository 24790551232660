(function() {
	'use strict';
	angular.module('myApp.recoveryPassword', ['ui.router', 'myApp.dataCollector', 'myApp.auth'])

		   .config([
			   '$stateProvider', function($stateProvider) {
				   $stateProvider
					   .state('home.public.recovery', {
						   url: '/recovery',
	                       views: {
	                            content: {
	                                templateUrl: 'recovery_password/recovery_password.html',
	                                controller: 'RecoveryCtrl',
	                                controllerAs: 'ctrl',
	                            }
	                        }
					   })
					   .state('home.public.recovery_success', {
						   url: '/recovery-success',
						   views: {
	                            content: {
	                                templateUrl: 'recovery_password/recovery_password_success.html',
	                                controller: 'RecoverySuccessCtrl',
	                                controllerAs: 'ctrl',
	                            }
	                        },
						   
						   resolve: {
							   email: [
								   'DataCollector', function(DataCollector) {
									   var data = DataCollector.getData();
									   return data.user;
								   }
							   ]
						   }
					   });
			   }
		   ])

		   .controller('RecoveryCtrl', [
			   '$state', 'DataCollector', 'Auth', function($state, DataCollector, Auth) {
				   var ctrl = this;

				   ctrl.formSubmitted = false;
				   ctrl.stepData = DataCollector.getData();

				   ctrl.onSubmit = function(form) {

					   if(form.$invalid) {
						   ctrl.formSubmitted = true;
					   } else {
						   DataCollector.addData(ctrl.stepData);
						   Auth.recovery(ctrl.stepData.user)
							   .then(function() {
								   $state.go('home.public.recovery_success');
							   }, function(error) {
								   ctrl.error = error;
							   });
					   }
				   };
			   }
		   ])

		   .controller('RecoverySuccessCtrl', [
			   'email', '$state', function(email, $state) {
				   this.email = email;
				   if(!email) {
					   $state.go('home.public.recovery');
				   }
			   }
		   ]);
}());

