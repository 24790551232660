(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('success/consultant.html',
    '<div class="modal fade consultant-modal" tabindex="-1" role="dialog" aria-labelledby="setPasswordModalLabel" aria-hidden="true"><div class="modal-dialog" role="document"><div class="modal-content wrapper private"><div class="modal-header pb-0"><h2 class="modal-title theme" id="setPasswordModalLabel">Tramitar con préstamo personal</h2><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="alert alert-danger alert-dismissible row" ng-if="modalCtrl.error"><a class="close" data-dismiss="alert" aria-label="close" ng-click="modalCtrl.error=false">&times;</a> {{ modalCtrl.error }}</div><div class="modal-body"><p>En caso de que te falte algo de dinero, puedes completar la hipoteca con un <span class="theme">préstamo personal</span>.</p><br><div class="row pb-4"><div class="col-12"><form data-ng-submit="modalCtrl.onSubmit(form)" name="form" novalidate ng-class="{submitted: modalCtrl.formSubmitted}"><div class="form-group mb-0"><button class="btn-theme m-0" style="width: 100%; max-width: 100%; border: none" type="submit">Tramitar con préstamo</button></div></form></div></div></div></div></div></div>');
}]);
})();
