(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('login/login.html',
    '<div class="flex-container" style="min-height: 45vh"><div class="flex-column"><div class="flex-item"><form class="narrow" data-ng-submit="ctrl.onSubmit(form)" autocomplete="false" name="form" novalidate ng-class="{submitted: ctrl.formSubmitted}"><div class="alert alert-danger alert-dismissible row" ng-if="ctrl.error"><a class="close" data-dismiss="alert" aria-label="close" ng-click="ctrl.error=false">&times;</a> {{ ctrl.error }}</div><div class="row"><div class="form-group col-12 px-0" ng-class="{error: form.user.$error.required || form.user.$error.email}"><div class="d-flex flex-column"><label for="user">Email</label><input type="email" class="form-control" id="user" name="user" autocomplete="false" ng-model="ctrl.stepData.user" aria-describedby="user-error" placeholder="micuenta@email.com" required><div id="user-error" class="error-message" ng-messages="form.user.$error"><div ng-message="required">Campo obligatorio</div><div ng-message="email">Email no válido</div></div></div></div><div class="form-group col-12 px-0" ng-class="{error: form.password.$error.required}"><div class="d-flex flex-column"><label for="password">Contraseña</label><input type="password" class="form-control" id="password" name="password" autocomplete="new-password" ng-model="ctrl.stepData.password" aria-describedby="password-error" placeholder="Contraseña" required><div id="password-error" class="error-message" ng-messages="form.password.$error"><div ng-message="required">Campo obligatorio</div></div></div></div></div><div class="row justify-content-end"><a ui-sref="home.public.recovery" class="theme">¿Has olvidado tu contraseña?</a></div><div class="row justify-content-center last"><button type="submit">Iniciar sesión</button></div></form></div></div></div>');
}]);
})();
