(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('recovery_password/recovery_password_success.html',
    '<div class="flex-container" style="min-height: 45vh"><div class="flex-column"><div class="flex-item"><h4 class="bold">Email de recuperación de contraseña enviado</h4><h3>{{ ctrl.email }}</h3></div><div class="flex-item"><p>Por favor, abre tu correo y busca un email enviado por Helloteca. Haz click en el enlace del email recibido para introducir tu nueva contraseña</p></div></div></div>');
}]);
})();
