(function(module) {
try {
  module = angular.module('templates');
} catch (e) {
  module = angular.module('templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('home/usuario.html',
    '<div class="link d-none d-xl-inline" ng-if="!usuarioCtrl.user"><a ui-sref="home.login" class="theme">INICIAR SESIÓN</a></div><div class="link d-none d-xl-inline" ng-if="usuarioCtrl.user"><span class="d-none d-xl-inline">{{ usuarioCtrl.user }} -</span> <a ui-sref="logout" class="theme">Salir</a></div>');
}]);
})();
