(function () {
    'use strict';
    angular.module('myApp.success', ['ui.router', 'myApp.dataCollector', 'myApp.API', 'ngSanitize', 'myApp.auth'])

        .config([
            '$stateProvider', function ($stateProvider) {
                $stateProvider
                    .state('home.public.success', {
                        url: '/{token_id}/success',
                        views: {
                                content: {
                                    templateUrl: 'success/success.html',
                                    controller: 'SuccessCtrl',
                                    controllerAs: 'ctrl',
                                }
                            },
                        onEnter: [
                            '$state', 'DataCollector', function ($state, DataCollector) {
                                if (DataCollector.getCurrentStep() !== 'success') {
                                    $state.go('home.' + DataCollector.getCurrentStep());
                                }
                            }
                        ],
                        resolve: {
                            token: [
                                'DataCollector', '$state', 'API', '$stateParams', function (DataCollector, $state, API, $stateParams) {
                                    console.log($stateParams);
                                    DataCollector.setToken($stateParams.token_id);
                                    var token = DataCollector.getToken();
                                   
                                    return API.getCurrentData(token)
                                        .then(function (data) {
                                            DataCollector.addDataFromRemote(data);


                                            return token;
                                        })
                                        .catch(function () {
                                            DataCollector.setToken();
                                            $state.go('home.public.login', $stateParams, {reload: true});
                                        });
                                }
                            ],
                            PreScoring: [
                                'API', 'token', function (API, token) {
                                    console.log(token);
                                    return API.getPreScoring(token);
                                }
                            ]
                        }
                    });
            }
        ])

        .controller('SuccessCtrl', [
            'DataCollector', 'PreScoring', 'API', '$state', 'Auth', '$scope', '$q', 'broker', '$stateParams',
            function (DataCollector, PreScoring, API, $state, Auth, $scope, $q, broker, $stateParams) {
                if (!PreScoring.Scoring) {
                    alert('Ha ocurrido un problema durante el proceso de prescoring. Por favor, revisa los datos que nos has enviado. ');
                    $state.go('home.public.view1');
                    return;
                }

                var ctrl = this;
                ctrl.broker = PreScoring.origen;
                // MIXPANEL DATA
                mixpanel.track("BrokerPrescoringResultLoaded", {"Scoring": PreScoring.Scoring.code,"porcentaje_a_financiar":PreScoring.Scoring.porcentaje_a_financiar,"result":PreScoring.Scoring.result});
                mixpanel.people.set({
                    "Prescoring Value": PreScoring.Scoring.code
                });
                // END MIXPANEL Success
                ctrl.token_id=$stateParams.token_id;
                ctrl.scoring = PreScoring.Scoring;
                ctrl.vivienda = PreScoring.Vivienda;
                ctrl.actions = PreScoring.Scoring.actions;
                ctrl.banks = PreScoring.Scoring.data_for_banks;
                ctrl.termsApproved = false;
                ctrl.requireTermsApproved = false;
                ctrl.showBanks = false;
                ctrl.showAvals = false;
                ctrl.showConsultant = false;
                ctrl.showBrokers = false;

                ctrl.titleImage = 'images/hand-icon.svg';
                ctrl.goIconTemplate = 'success/go-icon-menor-95.html';
                ctrl.statusScoring = 'menor';              
                if (PreScoring.Scoring.code === 'mayor_95') { 
                    ctrl.titleImage = 'images/warning-icon.svg';
                    ctrl.statusScoring = 'mayor';
                    ctrl.goIconTemplate = 'success/go-icon-mayor-95.html';

                    //BEGIN LuckyOrange
                    window._loq = window._loq || []; // ensure queue available
                    window._loq.push(["tag", "success_no_viable", true, true]); // this will tag, star, will overwrite any existing tags
                    //END LuckyOrange
                } else {
                    //BEGIN LuckyOrange
                    window._loq = window._loq || []; // ensure queue available
                    window._loq.push(["tag", "success_si_viable", true, true]); // this will tag,  star, will overwrite any existing tags
                    //END LuckyOrange
                }

                ctrl.doAction = function (action, extra) {

                    var deferred = $q.defer();

                    if (ctrl.termsApproved) {
                        $scope.$emit('loading:show');
                        mixpanel.track("BrokerPrescoringResultConvertClicked");
                        API.postUrl(action.endpoint + (extra || ''))
                            .then(function (response) {
                                Auth.login(DataCollector.getLoginData())
                                    .then(function () {
                                        // MIXPANEL DATA
                                        mixpanel.track("BrokerPrescoringResultConvertOK");
                                        // END MIXPANEL DATA
                                        deferred.resolve();

                                        $state.go('home.private.private1', {'broker': ctrl.broker}, {reload: true});

                                    }, function (error) {
                                        console.log(error);

                                        // MIXPANEL DATA
                                        mixpanel.track("BrokerPrescoringResultConvertError", {"Error": error});
                                        // END MIXPANEL DATA
                                        if (error == 'La contraseña no es correcta') {
                                            alert('Ya existe un usuario con la misma dirección de correo. Accede con tu contraseña.');
                                            deferred.reject();
                                            $state.go('home.public.login', {reload: true});
                                        } else {
                                            deferred.reject(error);
                                        }
                                    });
                            })
                            .catch(function (response) {

                                // MIXPANEL DATA
                                mixpanel.track("BrokerPrescoringResultConvertError", {"ErrorUrl": action.endpoint});
                                // END MIXPANEL DATA

                                // TAG MANAGER
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'registrationComplete'
                                });
                                //END TAG MANAGER
                                deferred.reject('Ha ocurrido un error. Por favor, vuelve a intentarlo.');
                            });
                    } else {
                        ctrl.requireTermsApproved = true;
                        deferred.reject('Por favor, acepta las condiciones de servicio antes de continuar.');
                    }
                    return deferred.promise;
                };

                ctrl.doActionWithApprovedTermsAndAvals = function () {
                    var deferred = $q.defer();
                    var user = DataCollector.getUserData();
                    user.avalista = 'si';

                    mixpanel.track("BrokerPrescoringResultAddAvalClicked");
                    API.sendUserData(user)
                        .then(function () {
                            deferred.resolve();
                            mixpanel.track("BrokerPrescoringResultAddAvalOK");
                            $state.go('home.public.view1', $stateParams);
                        });
                    return deferred.promise;
                };

                ctrl.doActionWithApprovedTermsAndConsultant = function () {
                    var deferred = $q.defer();
                    var user = DataCollector.getUserData();
                    user.prestamo_personal = 'si';

                    mixpanel.track("BrokerPrescoringResultAddLoanClicked");
                    API.sendUserData(user)
                        .then(function () {
                            deferred.resolve();
                            mixpanel.track("BrokerPrescoringResultAddLoanOK");
                            $state.go('home.public.success', $stateParams, {reload: true});
                        });
                    return deferred.promise;
                };
            }
        ]);
}());

